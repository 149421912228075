@import '~@devsta/ui-kit/scss/core/vars';

$upload-btn-width: 225px;

.post-details-form {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  overflow-y: auto;
  width: 100%;

  &__video-details {
    align-items: center;
    background: $white;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    margin: 0;
    margin-bottom: 20px;
    padding: 20px;
  }

  &__content {
    border-top: 1px solid $cat-skill-white;
    margin-top: 20px;
    padding-top: 20px;

    &__inner {
      background: $white;
      border-radius: 6px;
      padding: 20px;
      padding-bottom: 15px;

      textarea {
        resize: vertical;
      }

      > * {
        display: block;
        margin-bottom: 15px;
      }

      > .dropdown {
        &__label-wrapper {
          > div:nth-child(2) {
            color: #8e8e8e;
          }
        }
      }
    }
  }

  &__thumbnail {
    align-items: center;
    background: $white;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    padding: 20px;

    > .image {
      border-radius: 0;
    }
  }

  @media only screen and (max-width : $media-sizes-small) {
    &__thumbnail {
      flex-direction: column;

      &__upload-button {
        margin-top: 15px;
        width: 100%;
      }
    }
  }
}
