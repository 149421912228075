@import '~@devsta/ui-kit/scss/core/vars';

.seek-bar {
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 10px;
  position: relative;
  transition: opacity 0.3s ease;
  width: 100%;

  &--dragging::before {
    height: 100%;
  }

  &--loading {
    opacity: 0.5;
    pointer-events: none;
  }

  &__handle {
    background: $white;
    border: 0;
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(140, 150, 169, 0.5);
    cursor: grab;
    height: 25px;
    position: absolute;
    visibility: visible;
    width: 15px;
    z-index: 3;

    &:focus {
      border-color: none;
      box-shadow: none;
    }
  }

  &::before {
    background: $white-75;
    border-radius: 4px;
    content: '';
    height: 8px;
    left: 0;
    position: absolute;
    transition: height 0.2s ease-in-out;
    width: 100%;
  }

  &__fill {
    background: $monkey;
    border-radius: 4px;
    height: 8px;
    transition: height 0.2s ease-in-out;
    width: 0;
    z-index: 1;
  }

  &:hover &__fill,
  &__fill--dragging {
    height: 100%;
  }

  &:hover &__handle {
    visibility: visible;
  }

  &:hover {
    height: 10px;

    &::before {
      height: 100%;
    }
  }
}
