@import '~@devsta/ui-kit/scss/core/vars';

.delete-confirmation-modal {
  .rodal-dialog {
    max-width: 700px !important;
  }

  .modal {
    &__save-button {
      background: $red-apple;
      border-color: $red-apple;
    }
  }

  &--disabled .modal {
    &__save-button {
      background: unset;
      border-color: transparent;
    }
  }

  &__warning {
    color: $thunder-bird !important;
  }

  &__warnings {
    background: $white;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 10px 20px;
  }
}
