@import '~@devsta/ui-kit/scss/core/vars';

.library-asset-list {
  min-height: 100%;

  &--small {
    .list-item {
      height: initial !important;
      margin-bottom: 20px !important;
      min-height: 130px;
      padding-bottom: 25px;
      padding-top: 25px;
    }
  }

  &--hide-assets {
    .list-container {
      &__content {
        display: none;
      }
    }
  }
}

