.expandable-addon {
  &__subtitle {
    margin-left: 40px;
    margin-top: 10px;
  }

  &__children {
    display: grid;
    grid-column-gap: 100px;
    grid-row-gap: 40px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    margin-top: 40px;
    padding-left: 40px;
  }

  &__checkbox {
    align-items: center;
    display: flex;
    white-space: nowrap;

    > .text {
      margin-left: 20px;
    }
  }
}
