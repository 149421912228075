@import '~@devsta/ui-kit/scss/core/vars';

$border-radius: 6px;

.upload-progress-draggable {
  bottom: 0;
  height: 100vh;
  left: 0;
  min-height: calc(100vh - 270px);
  pointer-events: none;
  position: fixed;
  width: 100vw;
}

.upload-progress {
  bottom: 30px;
  left: 30px;
  position: fixed;
  width: 330px;
  z-index: 10;

  &__header {
    align-items: center;
    background-color: $black;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    box-shadow: 0 0 4px $black;
    color: $white;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: $font-sizes-small;
    font-weight: $font-weights-medium;
    height: 50px;
    justify-content: center;
    padding: 0 15px;
    text-align: left;
    user-select: none;

    &--collapsed {
      border-radius: $border-radius;
    }

    &--collapsed > &__info > &__info__percents {
      color: $nevada;
    }

    > div {
      width: 100%;
    }

    &__info {
      display: flex;
      height: 20px;
      justify-content: space-between;

      &__percents {
        align-items: center;
        display: flex;
        font-size: $font-sizes-small;

        &__bytes-progress {
          margin-right: 5px;
        }

        &__toggle {
          margin-left: 10px;
        }
      }

      &__title {
        align-items: center;
        display: flex;
      }
    }
  }

  &__body {
    align-items: center;
    background-color: $white;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    box-shadow: 0 2px 4px rgba(140, 150, 169, 0.5);
    display: flex;
    flex-direction: column;
    height: 240px;
    overflow: scroll;
    padding: 15px;
    transition: all 0.3s;

    &--hidden {
      height: 0;
      padding: 0 15px;
    }

    .upload-item:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}
