@import '~@devsta/ui-kit/scss/core/vars';

.embed-code {
  .modal {
    &__footer {
      flex-wrap: nowrap;
    }
  }

  &__content {
    > *:not(:last-child) {
      display: inline-block;
      margin-bottom: 20px;
    }

    textarea {
      min-height: 65px;
      resize: vertical;
    }
  }
}
