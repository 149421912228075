@import '../../../../scss/core/vars';

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% { -webkit-transform: scale(0); }
  40% { -webkit-transform: scale(1); }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.loader {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 10%;
  width: 100%;

  &__spinner {
    text-align: center;

    &__bounce1,
    &__bounce2,
    &__bounce3 {
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      background-color: $monkey;
      border-radius: 100%;
      display: inline-block;
      height: 30px;
      margin-right: 10px;
      width: 30px;
    }

    &__bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    &__bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }

    &__bounce3 {
      margin-right: 0;
    }
  }

  &--dark-theme {
    background: $night-rider;

    > * {
      color: $white;
    }
  }

  &--dark-theme &__text {
    &__letter {
      color: $white !important;
    }
  }

  &__title {
    margin-top: 10px;
  }
}

