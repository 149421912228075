@import '../../../../scss/core/vars';

.resource-members {
  align-items: center;
  display: flex;
  user-select: none;

  &__member {
    align-items: center;
    background-color: $white;
    border: 3px solid transparent !important;
    border-radius: 50%;
    box-sizing: content-box !important;
    display: flex;
    justify-content: center;
    position: relative;
    transition: all 0.2s ease-in-out;

    &:hover {
      border-color: $white !important;
      box-shadow: $shadows-soft;
      transform: scale(1.01) translateY(-5px);
      z-index: 10 !important;
    }

    &--dark-mode {
      background-color: $cod-grey;
      border-color: $cod-grey !important;
    }

    &:not(:last-child) {
      margin-right: -10px;
    }

    &--add-icon {
      cursor: pointer;
      margin-right: 10px;
      z-index: 0;
    }

    &__quantity {
      color: $white;
      letter-spacing: 1px;
      position: absolute;
      text-align: center;
      user-select: none;
      z-index: 2;
    }

    &__owner {
      position: absolute;
      right: 0;
      top: 28px;
      z-index: 1;

      > .icon-container svg {
        overflow: visible;

        path {
          stroke: $white;
          stroke-width: 4px;
        }
      }
    }

    &--with-more::after {
      background: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      content: '';
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }

  &__total {
    color: $regent;
    font-size: $font-sizes-micro;
    font-weight: $font-weights-medium;
    margin-left: 15px;
  }
}
