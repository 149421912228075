@import '../../../../scss/core/vars';

.nav {
  &__menu {
    &-container {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      height: 100%;
      padding-bottom: 20px;
      padding-top: 20px;
    }

    &__primary-action {
      align-items: center;
      display: flex;
      margin: 0 0 30px 20px;
      overflow: hidden;
      white-space: nowrap;

      > button {
        margin-right: 20px;
      }

      > .text {
        color: inherit;
      }
    }

    &__logo {
      margin-bottom: 50px;

      > button {
        align-items: center;
        display: flex;

        > .text {
          margin-left: 22px;
          text-align: left;
        }
      }
    }
  }
}
