@import '~@devsta/ui-kit/scss/core/vars';

.burnin-captions {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  > button {
    margin-left: 15px;
    min-width: 135px;
    width: 135px;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    margin-right: auto;

    > * {
      margin-right: 15px;
    }
  }
}
