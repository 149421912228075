@import '~@devsta/ui-kit/scss/core/vars';

.drop-files {
  &__container {
    display: flex;
    flex-grow: 1;

    > div:first-child {
      flex-grow: 1;
    }
  }

  &--active {
    align-items: center;
    background: $monkey;
    border: 3px dashed $russian;
    border-radius: 6px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;

    > small {
      color: $white;
    }

    > p {
      color: $white;
    }
  }
}
