@import '~@devsta/ui-kit/scss/core/vars';

.no-organisations {
  align-items: center;
  background: $white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  min-height: 100vh;
  overflow-y: scroll;
  padding: 10px;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;

  &__close-icon {
    position: absolute;
  }

  &__inner {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    width: 100%;

    @media only screen and (max-width : $media-sizes-small) {
      flex-direction: column;
      text-align: center;
    }

    &__icon {
      height: auto;
      margin-right: 50px;
      max-width: 250px;
      position: relative;
      width: 40%;
  
      &::before {
        background: $red-apple;
        border-radius: 6px;
        bottom: -20px;
        content: '';
        height: 100px;
        position: absolute;
        right: 20px;
        transform: rotate(45deg);
        width: 20px;
  
        @media only screen and (max-width : $media-sizes-small) {
          bottom: 0;
          height: 50px;
          right: 20px;
          width: 10px;
        }
      }
  
      &::after {
        background: $red-apple;
        border-radius: 6px;
        bottom: 20px;
        content: '';
        height: 20px;
        position: absolute;
        right: -20px;
        transform: rotate(45deg);
        width: 100px;
  
        @media only screen and (max-width : $media-sizes-small) {
          bottom: 20px;
          height: 10px;
          right: 0;
          width: 50px;
        }
      }
  
      @media only screen and (max-width : $media-sizes-small) {
        margin-right: 0;
      }
  
      > svg {
        height: auto;
        width: 100%;
      }
    }

    &__text {
      > button {
        margin-top: 50px;
      }
  
      @media only screen and (max-width : $media-sizes-small) {
        align-items: center;
        display: flex;
        flex-direction: column;
      }

      &__header {
        font-size: $font-sizes-large;
        margin-bottom: 0;
        margin-top: 5%;
    
        @media only screen and (max-width : $media-sizes-small) {
          margin-top: 10%;
        }
      }

      &__suggest {
        margin-bottom: 0;
      }
    }
  }

  &__assist {
    margin-bottom: 2%;
    margin-top: 3%;
    text-align: center;

    > button {
      font-size: $font-sizes-default;
      font-weight: bold;
    }

    @media only screen and (max-width : $media-sizes-small) {
      font-size: $font-sizes-small;

      > button {
        font-size: $font-sizes-small;
      }
    }
  }
}
