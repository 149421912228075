@import '~@devsta/ui-kit/scss/core/vars';

.add-reply {
  align-items: center;
  border-top: 1px solid $cat-skill-white;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  padding-top: 14.5px;
  position: relative;

  > .rich-editor {
    > .rich-editor {
      &__editor {
        max-height: 40px;
        padding: 8px 11px;
      }
    }
  }

  &__footer {
    align-items: flex-end;
    display: flex;
    font-size: $font-sizes-small;
    justify-content: space-between;
    margin-left: 10px;
  }
}
