@import '~@devsta/ui-kit/scss/core/vars';
@import '../../../../../common/styles/vars';

.titled-form-block {
  width: 100%;

  &__title {
    margin-bottom: 15px;
  }

  &__content {
    align-items: center;
    border: 1px solid $sterling;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    padding: 30px;

    &__children {
      align-items: center;
      display: flex;
      flex: 1;
      justify-content: space-between;
      margin-left: 25px;

      @media only screen and (max-width : $small-breakpoint) {
        margin-left: 20px;
      }
    }
  }
}
