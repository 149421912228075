@import '~@devsta/ui-kit/scss/core/vars';

.list-items {
  &__name {
    color: #000;
    font-size: $font-sizes-small;
    letter-spacing: 1px;
    line-height: 17px;
    margin-bottom: 8px;
    text-align: left;
  }

  &__row {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    &__description {
      display: flex;

      > .icon-container {
        margin-right: 5px;
      }
    }
  }

  &__horizontal-line {
    margin: 18px auto;
    opacity: 0.5;

    &:last-child {
      display: none;
    }
  }
}
