@import '../../../scss/core/vars';
@import '../../common/styles/mixins/menu';

.menu-button {
  display: inline-flex;

  > button {
    height: 40px;
    min-height: 40px;
  }

  &__menu {
    @include menu;
    display: inline-flex;
    flex-direction: column;

    margin-top: 10px;
    opacity: 0;
    position: fixed;
  }
}
