@import '~@devsta/ui-kit/scss/core/vars';
@import '~@devsta/ui-kit/scss/core/mixins';

.upload-files-modal {
  width: 100%;

  .rodal-dialog {
    width: 80% !important;
  }

  .modal {
    &__save-button {
      border: 0;
    }
  }

  &__assets {
    height: 100%;
    overflow-y: auto;
    width: 100%;

    &__asset {
      align-items: center;
      display: flex;
      margin: 5px;
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }

      > .icon-container {
        margin-left: auto;
      }

      .asset-preview {
        min-width: 75px;
      }
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;

    > div {
      flex: 2;
    }

    > *:not(:last-child) {
      margin-right: 40px;
    }

    &__dnd {
      box-sizing: border-box;

      &__drop-zone {
        align-items: center;
        border: 2px solid transparent;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 240px;
        justify-content: center;
        width: 100%;

        &--full {
          cursor: default;
        }

        &--empty {
          @include grey-dashed-border;
          border: 0;
          border-radius: 6px;

          &:hover {
            @include grey-dashed-border-hover;
          }
        }

        &--active:not(&--full) {
          background: $monkey;
          border-color: transparent;
          opacity: 1;

          small,
          span,
          p {
            color: $white !important;
          }
        }
      }
    }

    &__source-from {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      width: 100%;

      > *:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &__source-from-container {
      flex: 1;
    }
  }

  @media only screen and (max-width: $media-sizes-medium) {
    &__content {
      box-sizing: border-box;
      flex-direction: column;

      > *:not(:last-child) {
        margin-right: 0;
      }

      &__dnd {
        width: 100%;
      }

      &__source-from {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: unset;
      }

      &__source-from-container {
        margin-top: 20px;
      }
    }
  }
}
