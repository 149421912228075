.file-type-icon {
  align-items: center;
  background: rgba(0, 0, 0, 0.15);
  bottom: 5px;
  display: flex;
  height: 25px;
  justify-content: center;
  position: absolute;
  right: 5px;
  width: 25px;
  z-index: 2;
}
