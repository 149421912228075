@import '../../../../scss/core/vars';

.stage {
  pointer-events: none;

  &__arrow {
    align-items: center;
    display: flex;
    height: 40px;
    position: relative;

    &__left {
      border-bottom: 20px solid $russian;
      border-left: 20px solid transparent;
      border-top: 20px solid $russian;
      display: inline-block;
      height: 0;
      padding-right: 1px;
      position: relative;
      width: 0;

      &::before {
        border-bottom: 20px solid $white;
        border-left: 20px solid transparent;
        border-top: 20px solid $white;
        content: '';
        display: inline-block;
        height: 0;
        left: -18px;
        position: absolute;
        top: -20px;
        transform: scale(0.95);
        width: 0;
      }
    }

    &__ctr {
      align-items: center;
      background: $white;
      border-bottom: 1px solid $russian;
      border-top: 1px solid $russian;
      box-sizing: border-box;
      display: flex;
      height: 40px;
      padding: 0 20px;
      position: relative;
      text-align: center;

      &__text {
        font-weight: $font-weights-medium;
        margin: 0;
        user-select: none;
        white-space: nowrap;
      }
    }

    &__right {
      border-bottom: 20px solid transparent;
      border-left: 20px solid $russian;
      border-top: 20px solid transparent;
      display: inline-block;
      height: 0;
      position: relative;

      &::after {
        border-bottom: 20px solid transparent;
        border-left: 20px solid $white;
        border-top: 20px solid transparent;
        content: '';
        display: inline-block;
        height: 0;
        position: absolute;
        right: 1px;
        top: -20px;
        transform: scale(0.95);
        width: 0;
      }
    }
  }

  &:first-of-type &__arrow {
    &__left {
      display: none;
    }

    &__ctr {
      border-bottom-left-radius: 6px;
      border-left: 1px solid $russian;
      border-top-left-radius: 6px;
    }
  }

  &--clickable:not(.stage--disabled) {
    cursor: pointer;
    pointer-events: all;
  }

  &--selected &__arrow {
    &__left {
      border-bottom-color: $denim;
      border-top-color: $denim;

      &::before {
        border-bottom-color: $denim;
        border-top-color: $denim;
      }
    }

    &__ctr {
      background: $denim;
      border-color: $denim !important;

      > p {
        color: $white;
        font-weight: $font-weights-medium;
      }
    }

    &__right {
      border-left-color: $denim;

      &::after {
        display: none;
      }
    }
  }

  &:hover:not(&--selected) &__arrow {
    &__left {
      &::before {
        border-bottom-color: $parthenon;
        border-top-color: $parthenon;
      }
    }

    &__ctr {
      background: $parthenon;
    }

    &__right {
      &::after {
        border-left-color: $parthenon;
      }
    }
  }
}

@supports (clip-path: inset(50%)) {
  .stage {
    &__arrow {
      align-items: center;
      display: flex;
      padding-right: 20px;

      &__ctr {
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
      }

      &__right {
        background: $white;
        border: 1px solid $russian;
        border-radius: 7px 7px 7px 0;
        box-sizing: border-box;
        clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
        display: block;
        height: 32px;
        position: absolute;
        right: 11px;
        transform: rotate(-135deg);
        width: 32px;

        &::after {
          display: none;
        }
      }
    }

    &--selected &__arrow {
      &__right {
        background: $denim;
        border: 0;
      }
    }

    &:hover:not(&--selected) &__arrow {
      &__right {
        background: $parthenon;
      }
    }
  }
}
