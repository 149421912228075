.caption {
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;

  > .dropdown {
    margin-right: 10px;
    min-width: 280px;
    width: 250px;
  }

  > .button {
    margin-left: auto;
  }
}
