@import '~@devsta/ui-kit/scss/core/vars';

.comment-button {
  align-items: center;
  border: 0;
  border-radius: 50%;
  color: $white;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  height: 50px;
  justify-content: center;
  position: absolute;
  width: 50px;
  z-index: 2;
}
