@import '../../../../scss/core/vars';

.accordion {
  &__title {
    user-select: none;
  }

  &--dark-mode &__title {
    color: $white;
  }
}
