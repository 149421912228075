@import '~@devsta/ui-kit/scss/core/vars';

.explore-more {
  border-radius: 6px;
  height: 250px;
  overflow: hidden;
  position: relative;
  width: 100%;

  > .image::before {
    background: $dark-denim-50;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__content {
    bottom: 0;
    color: $white;
    left: 0;
    line-height: 33px;
    padding: 50px;
    position: absolute;
    right: 0;
    top: 0;

    > * {
      margin: 0;
    }

    > button {
      margin-top: 35px;
    }
  }
}
