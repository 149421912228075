@import '../../../../scss/core/vars';

.page-header {
  border-bottom: 1px solid $russian;
  box-sizing: border-box;
  max-height: 100px;
  min-height: 100px;
  width: 100%;

  &__action-content {
    > *:not(:first-child) {
      margin-left: 20px;
    }
  }
}
