@import '~@devsta/ui-kit/scss/core/vars';
.comment-assets {
  margin-top: 15px;
  margin-bottom: 15px;
  &__placeholder {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
  }
  > .action-menu,
  .action-menu__btn-container {
    width: 100%;
  }
}

.comment-asset {
  height: 60px;
  border-radius: 4px;
  border: 1px solid $russian;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  box-sizing: border-box;
  &__title {
    display: flex;
    overflow: hidden;
    margin-right: auto;
    margin-left: 10px;
  }
  > .button,
  > .download-asset-menu {
    margin-left: 10px;
    &-menu {
      top: 0;
      left: 0;
      bottom: auto;
      right: auto;
      transform: translate3d(172px, 188px, 0px);
    }
  }
}