@import '~@devsta/ui-kit/scss/core/vars';

$modal-width: 460px;

.confirm-modal {
  box-sizing: border-box;

  .rodal-dialog {
    max-width: $modal-width !important;

    .modal {
      padding: 45px 50px 35px;
    }
  }

  &__title {
    color: $black;
    font-size: $font-sizes-large;
    font-weight: $font-weights-medium;
    margin-bottom: 15px;
    text-align: center;
  }

  &__subtitle {
    color: $black;
    margin-bottom: 25px;
    text-align: center;
  }

  &__btn,
  &__cancel-btn {
    width: 100% !important;
  }

  &__btn {
    margin-bottom: 15px;
  }

  &__cancel-btn {
    color: $black;
  }
}
