@import '~@devsta/ui-kit/scss/core/vars';
$padding-bottom: 50px;

.library {
  flex: 1;
  overflow: auto;
  padding-bottom: $padding-bottom;

  .card-item,
  .list-item {
    .image {
      background-size: cover !important;
    }
  }
}
