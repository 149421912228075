.phone-number-input {
  .react-select {
    &__control {
      border-bottom-right-radius: 0 !important;
      border-top-right-radius: 0 !important;
      min-height: 38px !important;
      padding-left: 20px;
      padding-right: 10px;
    }

    &__value-container {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  &__drop-down {
    width: 100%;
  }

  .input-container__decorator:not(.input-container__decorator--is-suffix) {
    min-width: 120px;
    width: 120px;
  }
}
