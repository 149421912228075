@import '~@devsta/ui-kit/scss/core/vars';

$image-size: 40px;

.upload-item {
  align-items: center;
  display: flex;
  font-size: $font-sizes-small;
  width: 100%;

  &__details {
    margin-left: 10px;
    overflow: hidden;
    width: 100%;

    &--no-progress {
      > p {
        opacity: 0.3;
      }
    }

    &--error {
      > p {
        color: $thunder-bird;
      }
    }

    > p {
      font-size: $font-sizes-small;
      font-weight: $font-weights-medium;
      margin: 0;
      max-width: 220px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__reset-upload {
    input {
      display: none;
    }
  }
}
