.form-block-description {
  flex: 1;
  margin-right: 35px;

  > p {
    margin: 0;
  }

  > p:not(:first-of-type) {
    font-size: 12px;
    margin-bottom: 0;
  }
}
