@import '../../../../scss/core/vars';

.input-container {
  position: relative;
  width: 100%;

  &--grow {
    width: auto;
  }

  &--multiline {
    background: white;

    > textarea {
      background: transparent;
      border-radius: 4px;
      border-width: 1px;
      height: unset;
      line-height: 23px;
      padding-bottom: 8px;
      padding-top: 8px;
      position: relative;
      resize: vertical;
      z-index: 1;
    }
  }

  &--multiline {
    &.input-container {
      &--with-prefix {
        > textarea {
          padding-left: 45px;
        }
      }

      &--with-suffix {
        > textarea {
          padding-right: 45px;
        }
      }
    }
  }

  &--multiline &__decorator {
    align-items: flex-start;
    height: 100%;
    left: 0;
    padding-top: 19px;
    position: absolute;
    z-index: 0;

    > * {
      transform: translateY(-50%);
    }

    &--is-suffix {
      border-width: 0 !important;
      height: calc(100% - 20px);
      left: unset;
      right: 0;
      top: 0;
      z-index: 1;
    }
  }

  &__decorator {
    align-items: center;
    background: $white;
    box-sizing: border-box;
    cursor: text;
    display: flex;
    height: 40px;
    justify-content: center;
    min-width: 45px;
    order: 0;
    padding-left: 10px;
    width: 45px;

    &__button {
      background: none;
      border: 0;
      outline: none !important;
      padding: 0;
    }

    &--custom {
      order: 0;
    }

    &--is-suffix {
      order: 2;
      padding-left: 0;
      padding-right: 10px;
    }
  }
}
