@import '~@devsta/ui-kit/scss/core/vars';
@import '../../../../../common/styles/vars';

.link-social-button {
  &__button {
    &__icons {
      display: flex;
      width: 100%;

      > *:not(:last-child) {
        margin-right: 10px;
      }
    }
    @media only screen and (max-width: $small-breakpoint) {
      &__icons {
        margin-top: 15px;
      }
    }
  }
}
