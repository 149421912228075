@import '~@devsta/ui-kit/scss/core/vars';

.interactive-overlay {
  align-items: center;
  background: $black-50;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 2;

  > .icon-container {
    cursor: pointer;
    z-index: 2;
  }

  &__loading {
    cursor: default !important;
  }

  &__restart {
    margin-right: 20px;
  }
}
