@import '~@devsta/ui-kit/scss/core/vars';
@import '~draft-js/dist/Draft.css';
@import './headings';

.rich-editor {
  @include headings;

  box-sizing: border-box;
  max-width: 100%;
  min-width: 0;
  overflow: visible;
  position: relative;
  width: 100%;

  &__editor {
    background: $white;
    border: 1px solid $russian;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    box-sizing: border-box;
    font-size: $font-sizes-default;
    letter-spacing: 0.3px;
    line-height: 22px;
    overflow-y: auto;
    padding: 20px 30px 20px 20px; // right padding for paperclip icon
    width: 100%;

    > * {
      cursor: text;
    }

    .icon-container {
      cursor: pointer;
    }

    &--no-controls {
      border-radius: 6px;
      padding-bottom: 8px;
      padding-top: 8px;
    }

    &--disabled {
      background: $parthenon;
      color: $nevada;
      outline: none !important;

    }

    &:hover:not(:focus-within):not(&--disabled) {
      border-color: $regent;
    }

    &:focus-within(:not(&--disabled)) {
      border-color: $dark-denim;
    }

    span {
      font-weight: inherit;
    }

    /* stylelint-disable */
    .public-DraftEditorPlaceholder-inner {
      color: $nevada;
    }

    .public-DraftEditor-content {
      min-height: 20px;
    }

    .public-DraftStyleDefault-pre {
      background-color: rgba(0, 0, 0, 0.05);
      font-size: 16px;
      padding: 20px;
    }

    .public-DraftStyleDefault-ltr {
      direction: inherit;
      text-align: inherit;
    }

    .public-DraftStyleDefault-block {
      margin: 0;
    }

    /* stylelint-enable */

    .align-right {
      text-align: right;
    }

    .align-center {
      text-align: center;
    }

    .align-left {
      text-align: left;
    }

    .align-justify {
      text-align: justify;
    }
  }

  &__blockquote {
    background: #ebfaff;
    border: 1px solid #b9ecff;
    border-radius: 3px;
    color: $black;
    font-size: $font-sizes-small;
    padding: 20px;
  }

  &__style-button {
    color: $nevada;
    cursor: pointer;
    display: inline-block;
    margin-right: 16px;
    padding: 2px 0;
  }

  &__active-button {
    color: $archetypal;
  }
}

.mentions-dropdown {
  background-color: $white;
  border-radius: 6px;
  border-top: 0;
  box-shadow: $shadows-soft;
  box-sizing: border-box;
  left: 0;
  margin-top: 5px !important;
  max-height: 200px;
  overflow-y: scroll;
  padding-bottom: 8px;
  padding-top: 8px;
  position: absolute;
  top: 100%;
  transform: scaleY(0);
  transform-origin: 50% 0;
  width: 100%;
  z-index: 6;
}
