@import '~@devsta/ui-kit/scss/core/vars';

@mixin headings {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  h1 {
    font-size: 30px;
    font-weight: $font-weights-bold;
  }

  h2,
  h3,
  h4 {
    font-size: 25px;
    font-weight: $font-weights-medium;
  }

  h3 {
    font-size: $font-sizes-large;
  }

  h4 {
    font-size: 17px;
  }

  h5,
  h6 {
    font-size: $font-sizes-default;
    font-weight: $font-weights-regular;
  }

  h6 {
    font-size: $font-sizes-small;
  }
}
