@import '../../../scss/core/vars';

.text {
  align-items: center;
  color: $black;
  display: flex;
  font-weight: $font-weights-semibold;
  letter-spacing: 0.3px;
  line-height: 1.4em;
  margin-bottom: 0.5em;
  margin-top: 0.5em;

  &__prefix-icon {
    margin-right: 10px;
  }

  &__suffix-icon {
    margin-left: 10px;
  }

  // Types

  &--heading1 {
    font-size: 44px;
  }

  &--heading2 {
    font-size: 32px;
  }

  &--heading3 {
    font-size: 24px;
  }

  &--heading4 {
    font-size: 18px;
  }

  &--heading5 {
    font-size: 15px;
  }

  &--body {
    font-size: 14px;
    font-weight: $font-weights-regular;
    line-height: 22px;
  }

  &--link {
    color: $monkey;
    font-size: 14px;
    font-weight: $font-weights-regular;
    line-height: 22px;
    margin: 0;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: $aqua;
    }
  }

  &--caption {
    color: $nevada;
    font-size: 10px;
    font-weight: $font-weights-regular;
  }

  &--label {
    font-size: 12px;
    font-weight: $font-weights-regular;
  }

  &--timestamp {
    color: $nevada;
    font-size: 12px;
    font-weight: $font-weights-regular;
  }

  &--button {
    font-size: 14px;
    font-weight: $font-weights-semibold;
    margin: 0;
  }

  &--placeholder {
    color: $nevada;
    font-size: 14px;
    font-weight: $font-weights-regular;
  }

  &--subtitle {
    color: $nevada;
    font-size: 16px;
    font-weight: $font-weights-regular;
  }

  // Prefixes/Suffixes

  &__prefix-dagger {
    line-height: unset;
    margin-right: 5px;
    top: -0.2em;
  }

  &__suffix-dagger {
    line-height: unset;
    margin-left: 5px;
    top: -0.2em;
  }

  &--heading4 &__prefix-dagger,
  &--heading4 &__suffix-dagger,
  &--heading5 &__prefix-dagger,
  &--heading5 &__suffix-dagger {
    top: -0.1em;
  }

  &--body &__prefix-dagger,
  &--body &__suffix-dagger {
    top: -0.4em;
  }

  &__asterisk {
    color: $thunder-bird;
    margin-left: 5px;
  }

  // States & styles

  &--no-margin {
    margin-bottom: 0;
    margin-top: 0;
  }

  &--no-margin-top {
    margin-top: 0;
  }

  &--no-margin-bottom {
    margin-bottom: 0;
  }

  &--dark-theme {
    color: $white;
  }

  &--ellipsis-overflow {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &--centered {
    justify-content: center;
    text-align: center;
  }

  &--highlighted {
    font-weight: $font-weights-medium;
  }

  &--success {
    color: $monkey;
  }

  &--danger {
    color: $red-apple;
  }

  &--warning {
    color: $golden-tainoi;
  }

  &--neutral {
    color: $russian;
  }

  &--disabled {
    color: $nevada;
    user-select: none;
  }
}
