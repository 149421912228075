@import '~@devsta/ui-kit/scss/core/vars';

.comment-popover {
  z-index: 5;

  &__children {
    position: absolute;
  }

  &__container {
    background-color: $white;
    border-radius: 12px;
    box-shadow: $shadows-soft;
    box-sizing: border-box;
    display: flex;
    max-height: 485px;
    overflow-y: inherit;
  }
}
