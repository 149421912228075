@import '../../../../../../scss/core/vars';

.filters {
  &__search {
    align-items: center;
    display: flex;
    flex: 1;
    flex-wrap: wrap;

    &--mobile {
      width: 100%;
    }

    > .text {
      margin-left: 15px;
      min-width: 100px;
      white-space: nowrap;
      width: 100px;
    }
  }

  &__dropdown {
    .react-select {
      &__input {
        font-size: $font-sizes-small;
      }

      &__option {
        font-size: $font-sizes-small;
      }

      &__placeholder {
        font-weight: $font-weights-regular;
      }
    }
  }
}
