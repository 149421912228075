@import '../../../../scss/core/vars';

@mixin elevated-container($rounded: false) {
  background: $white;
  box-shadow: $shadows-soft;
  padding: 20px;

  @if $rounded {
    border-radius: 6px;
  }
}
