@import '~@devsta/ui-kit/scss/core/vars';

.addons {
  &__title {
    color: #000;
    font-size: $font-sizes-default;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 20px;
    margin-bottom: 10px;
    text-align: left;
  }

  &__body {
    background: #fff;
    border: 1px solid $cat-skill-white;
    border-radius: 1px;
    margin-right: 10px;
    padding: 18px 15px;
    text-align: left;

    &__list {
      height: 48vh;
      overflow-y: auto;
    }

    &__video-output {
      color: #000;
      font-size: $font-sizes-default;
      letter-spacing: 1px;
      line-height: 17px;
      margin-bottom: 8px;
      text-align: left;

      &__horizontal-line {
        margin: 18px auto;
      }
    }

    &__list-items {
      padding-left: 5px;
    }

    >button {
      width: 100%;
    }
  }
}
