@import '../../../../scss/core/vars';

@mixin button {
  align-items: center;
  border: 1px solid transparent;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  min-height: 50px;
  padding-left: 25px;
  padding-right: 25px;
  transition: background 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: max-content;

  @media only screen and (max-width: $media-sizes-small) {
    height: 40px;
    min-height: 40px;
  }
}

@mixin button--tertiary {
  background: $white;
  border: 1px solid $russian;

  .text--button {
    color: $denim;
  }

  &:hover {
    background: $parthenon;
    border: 1px solid $russian;
  }

  &:focus {
    background: $sterling;
    border: 1px solid $russian;
  }
}

@mixin button--disabled {
  background: $parthenon !important;
  border-color: transparent !important;
  cursor: default;
  outline: none !important;

  .text--button {
    color: $nevada !important;
  }
}
