@import '~@devsta/ui-kit/scss/core/vars';

.overlay-container {
  position: relative;

  &__disabled {
    pointer-events: none;
  }
}

.context-menu {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  left: 0;
  padding-bottom: 5px;
  padding-top: 5px;
  position: fixed;
  width: 8em;
  z-index: 10;

  >* {
    align-items: center;
    background: transparent;
    border: 0;
    color: $white;
    cursor: pointer;
    display: block;
    font-size: 11px;
    height: 100%;
    justify-content: space-between;
    margin-bottom: 0;
    margin-top: 11px;
    padding-bottom: 7px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    text-align: left;
    width: 9em;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}
