@import '~@devsta/ui-kit/scss/core/vars';

.comment-body {
  span {
    font-size: 14px;
    font-weight: $font-weights-regular;
    line-height: 22px;
  }

  &--small {
    span {
      font-size: 12px;
      line-height: 16px;
    }
  }
}
