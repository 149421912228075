@import '../../../../scss/core/vars';

$input-height: 40px;

.number-changer {
  align-items: center;
  display: inline-flex;
  height: $input-height;

  &__text {
    align-items: center;
    border: 1px solid $russian;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: center;
    margin: 0 5px;
    min-width: 60px;
    padding: 0 20px;
    text-align: center;
    user-select: none;

    &__value {
      margin-right: 5px;
      min-width: 18px;
    }
  }

  &__minus,
  &__add {
    border: 0;
    height: 40px;
    min-height: 40px;
    min-width: 40px;
    width: 40px;

    &:hover,
    &:focus {
      border: 0;
    }
  }
}
