@import '~@devsta/ui-kit/scss/core/vars';

.collaborators-list {
  background: $white;
  box-sizing: border-box;
  flex-grow: 1;
  flex-shrink: 1;
  height: 40vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px;
  width: 600px;

  &__loading {
    box-sizing: border-box;
    height: 75px;

    > svg {
      height: 100%;
      width: 100%;
    }
  }

  &__empty {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;

    &__text {
      color: $regent;
      flex-basis: 350px;
      font-size: 15px;
      letter-spacing: 1px;
      line-height: 20px;
      margin: auto;
      text-align: center;
    }
  }

  &__horizontal-line {
    border: 1px solid $russian;
    opacity: 0.5;

    &:last-child {
      display: none;
    }
  }

  &__item {
    display: flex;
    margin: 15px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &__info {
      align-self: center;
      margin-left: 15px;
      margin-right: auto;

      &__primary {
        color: $black;
        font-size: 15px;
        letter-spacing: 1px;
        line-height: 20px;
        margin: 0;
      }

      &__secondary {
        color: $nevada;
        font-size: 12px;
        letter-spacing: 1px;
        line-height: 17px;
        margin: 0;
      }
    }

    &__right-container {
      display: flex;
      flex-basis: 225px;

      &__add {
        justify-content: flex-end;
      }

      &__button {
        align-self: center;
      }

      &__role-dropdown {
        align-self: center;
        border: 1px solid $michael;
        border-radius: 6px;
        flex: 0 1 175px;
        margin: auto;

      }

      &__remove-icon {
        align-self: center;

        &--hidden {
          visibility: hidden;
        }
      }
    }
  }

  @media only screen and (max-width : $media-sizes-medium) {
    &__item {
      > .image {
        display: none;
      }
    }
  }

}
