@import '../../../../scss/core/vars';

.date-picker {
  &__block {
    align-items: center;
    display: flex;
    position: relative;
    width: 100%;
  }

  &__input {
    border: 1px solid #d4d9e2;
    border-radius: 6px;
    box-sizing: border-box;
    display: flex;
    height: 50px;
    min-height: 50px;
    padding: 10px 55px 10px 15px;
    width: 100%;

    &:focus {
      border: 1px solid #000;
      outline: none;
    }

    &:disabled {
      background: #f5f6f8;
      cursor: initial;
    }
  }

  &__toggle,
  &__clear {
    cursor: pointer;
    position: absolute;
    right: 15px;
  }

  &__clear {
    align-items: center;
    display: flex;
    height: 25px;
    justify-content: center;
    outline: none;
    position: absolute;
    width: 25px;
    z-index: 2;

    > svg {
      position: relative;
      z-index: 1;
    }

    &:hover::after {
      background: $cat-skill-white;
      border-radius: 50%;
      content: '';
      height: 25px;
      position: absolute;
      width: 25px;
      z-index: 0;
    }
  }
}

.flatpickr-calendar {
  .flatpickr-months {
    .flatpickr-prev-month,
    .flatpickr-next-month {
      > svg {
        fill: #82888a;

        &:hover {
          fill: #454849;
        }
      }
    }
  }

  /* stylelint-disable */
  .flatpickr-innerContainer {
    .flatpickr-days {
      .today {
        border-bottom-color: #e9e9e9;

        &:hover {
          background: #e9e9e9;
          border-color: #e9e9e9;
          cursor: pointer;
          outline: 0;
        }
      }

      .selected {
        background-color: #00a699;
        border-color: #00a699;
      }
    }
  }
  /* stylelint-enable */

}
