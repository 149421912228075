@import '../../../../scss/core/mixins';
@import '../../../../scss/core/vars';

.title-box {
  min-width: 0;
  position: relative;

  &__title-loader {
    height: 40px;
  }

  > *:first-child {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    height: 40px;

    &__title {
      color: $white;
    }
  }

  &__input {
    width: 100%;

    span {
      font-size: 24px;
      font-weight: $font-weights-semibold;
      max-width: 50%;
    }
  }

  &__suffix-icon-wrapper {
    align-items: center;
    display: flex;
    width: 100%;
  }

  &__subtitle-link {
    text-decoration: underline;
    text-decoration-color: $nevada;

    &--dark-mode {
      text-decoration-color: $white;
    }
  }

  &__suffix-icon {
    margin-left: 12px;
    margin-right: 12px;
  }

  &__suffix {
    margin-left: 20px;
    margin-right: 20px;
  }
}
