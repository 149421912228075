@import '../../../../scss/core/vars';

.icon-container {
  &--animated {
    .icon-rushedit {
      > g:last-of-type {
        animation: spin 2500ms linear infinite;
        transform-origin: 50% 58%;
      }
    }
  }
}
