@import '~@devsta/ui-kit/scss/core/vars';

.pay-invoice {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &__container {
    &__image-container {
      align-items: center;
      display: flex;
      width: 60%;

      @media only screen and (max-width: 900px) {
        display: none;
      }

      > svg {
        height: auto;
        width: 100%;
      }
    }

    &__content {
      box-sizing: border-box;
      display: flex;
      flex: 1;
      justify-content: space-between;
      padding: 120px !important;

      @media only screen and (max-width: 1000px) {
        padding: 80px !important;
      }

      @media only screen and (max-height: 1000px) {
        padding: 80px !important;
      }

      @media only screen and (max-width: 600px) {
        padding: 40px !important;
      }

      @media only screen and (max-height: 600px) {
        padding: 40px !important;
      }

      &__left {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 60px;

        @media only screen and (max-width: 900px) {
          margin-right: 0;
        }

        &__invoice-info {
          > .text {
            &--heading3 {
              display: block;
            }
          }

          > .radio-button {
            width: fit-content;

            &:first-of-type {
              margin-bottom: 10px;
              margin-top: 20px;
            }
          }

          &__payment {
            margin-left: 40px;
            margin-top: 10px;
            max-width: 200px;

            &__prefix {
              color: $regent;
              display: block;
              margin-right: 10px;
              padding-bottom: 3px;
              padding-top: 2px;
            }
          }

          &__pay-title {
            margin-top: 40px;
          }

          &__balance {
            color: $monkey;
          }

          &__currency {
            margin-left: 5px;
            margin-right: 5px;
          }
        }

        &__footer {
          align-items: center;
          display: flex;
          justify-content: flex-start;
          margin-top: 20px;
          width: 100%;

          > *:first-child {
            margin-right: 20px;
          }
        }

        &__success {
          align-items: flex-start;
          display: flex;
          flex-direction: column;

          &__footer {
            align-items: center;
            display: flex;
            margin-top: 40px;

            > *:first-child:not(:last-child) {
              margin-right: 20px;
            }
          }

          > .icon-container {
            margin-bottom: 30px;
          }
        }

        > *:not(:last-child) {
          margin-bottom: 30px;
        }

        > *:last-child:not(:first-child) {
          margin-top: 40px;
        }
      }

      > button {
        margin-top: 20px;
      }
    }
  }
}
