@import '../../../scss/core/vars';

.slider {
  width: 100%;

  > input {
    width: 100%;
  }

  &:not(&--disabled) input[type='range'] {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  input[type='range'] {
    align-items: center;
    -webkit-appearance: none;
    border-radius: 5px;
    display: flex;
    height: 10px;
    margin: auto;
    position: relative;
    transition: background 450ms ease-in;
    width: 100%;

    &::-webkit-slider-runnable-track {
      height: 10px;
      transform: translateY(-50%);
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      background: $white;
      border: 1px solid $russian; /* 1 */
      border-radius: 2px;
      box-shadow: 0 4px 10px 0 rgba(140, 150, 169, 0.25);
      height: 20px;
      width: 10px; /* 1 */
    }

    &::-moz-range-track {
      background: $parthenon;
      border-radius: 5px;
      height: 40px;
    }

    &::-moz-range-thumb {
      background: #fff;
      border: 3px solid #999;
      border-radius: 0 !important;
      box-shadow: -100vw 0 0 100vw $aqua;
      box-sizing: border-box;
      height: 40px;
      width: 20px;
    }

    &::-ms-fill-lower {
      background: $aqua;
    }

    &::-ms-thumb {
      background: #fff;
      border: 2px solid #999;
      box-sizing: border-box;
      height: 40px;
      width: 20px;
    }

    &::-ms-ticks-after {
      display: none;
    }

    &::-ms-ticks-before {
      display: none;
    }

    &::-ms-track {
      background: $parthenon;
      border: none;
      border-radius: 5px;
      color: transparent;
      height: 40px;
    }

    &::-ms-tooltip {
      display: none;
    }
  }

  &__labels {
    display: flex;
    height: 20px;
    margin-top: 20px;
    position: relative;

    &__label {
      box-sizing: border-box;
      overflow: hidden;
      position: absolute;
      transform: translateX(-50%);

      &__button {
        background: none;
        border: 0;
        cursor: pointer;
        padding: 0;

        &:hover {
          > .text {
            font-weight: $font-weights-semibold;
          }
        }

        > .text {
          justify-content: center;
          line-height: 22px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
        }
      }
    }
  }
}
