@import '~@devsta/ui-kit/scss/core/vars';

.upload {
  align-items: center;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;

  &__text {
    align-self: center;
    color: $white;
    flex: 1;
    text-align: center;

    > h3 {
      font-size: 30px;
      line-height: 1;
      margin-bottom: 0;
    }

    > p {
      font-size: 10px;
      font-weight: 500;
      margin: 0;
    }
  }

  &__bar {
    background: $aqua;
    bottom: 0;
    display: flex;
    height: 5px;
    position: absolute;
    transition: 0.5s ease;
    z-index: 2;
  }
}
