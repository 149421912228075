@import '~@devsta/ui-kit/scss/core/vars';
@import '~draft-js/dist/Draft.css';
@import './headings';

.editor-controls {
  background-color: $white;
  border-left: 1px solid $russian;
  border-right: 1px solid $russian;
  border-top: 1px solid $russian;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  font-size: $font-sizes-default;
  padding: 20px;
  user-select: none;

  &__block {
    align-items: center;
    display: flex;
    height: 40px;
    padding: 0 15px;

    > * {
      cursor: pointer;
    }

    > *:not(:first-child) {
      margin-left: 15px;
    }
  }

  &__divider {
    border-left: 1px solid $russian;
    height: 25px;
    margin-top: 8px;
  }

  &__headings {
    margin-right: 20px;
    min-width: 230px;

    .dropdown-option {
      &__content {
        &__label {
          font-size: $font-sizes-small;
          font-style: normal;
          font-weight: $font-weights-medium;
          line-height: 17px;
        }
      }
    }

    &__item {
      @include headings;
    }
  }
}
