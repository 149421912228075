@import '~@devsta/ui-kit/scss/core/vars';

// stylelint-disable-next-line
.Toastify {
  &__toast-body {
    width: calc(100% - 30px);
  }

  &__toast {
    border-radius: 6px;
    box-shadow: none;
    height: 60px;
    margin-bottom: 10px;
    padding: 15px;
    width: 300px;

    &__content {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-right: 15px;
    }

    > .icon-container {
      margin-bottom: auto;
      margin-right: -10px;
      margin-top: -10px;
      padding: 10px;
    }
  }

  &__toast--info {
    background: $black;
  }

  &__toast--success {
    background: $monkey;
  }

  &__toast--warning {
    background: #ff9f19;
  }

  &__toast--error {
    background: $red-apple;
  }

  &__toast--info,
  &__toast--success,
  &__toast--warning,
  &__toast--error {
    height: unset;
  }
}
