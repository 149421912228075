@import '../../../../scss/core/vars';

.tab {
  align-items: center;
  background: $white;
  border: 0;
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: center;
  outline-width: 0;
  padding: 15px;

  &:hover:not(&--selected) {
    background: $parthenon;
  }

  &--selected {
    cursor: default;
  }
}
