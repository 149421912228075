@import '~@devsta/ui-kit/scss/core/vars';

.comment-group {
  &:not(:last-of-type) {
    border-bottom: 1px solid $russian;
    margin-bottom: 30px;
    padding-bottom: 30px;
  }

  &__group-title {
    font-size: $font-sizes-default;
    font-weight: $font-weights-medium;
    margin-bottom: 15px;
  }
}
