.audio-preview {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 20px;
  position: relative;
  width: 100%;

  &__loading {
    position: absolute;
  }

  &__wave {
    height: 100%;
    pointer-events: none;
    visibility: hidden;
    width: 100%;

    > wave {
      height: 100% !important;
    }

    &--is-visible {
      visibility: visible;
    }
  }
}
