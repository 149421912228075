@import '~@devsta/ui-kit/scss/core/vars';

.comment-user-profile {
  align-items: center;
  display: flex;

  > span {
    font-size: $font-sizes-small;
  }

  .image {
    margin-right: 10px;
  }
}
