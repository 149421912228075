@mixin icon-colour($colour) {
  path {
    fill: $colour;
  }
}

@mixin icon-colour-stroke($colour) {
  path {
    stroke: $colour;
  }
}

@mixin breakpoint($breakpoint, $type: screen) {
  $bp: $breakpoint;
  $modifier: '';

  @if length($breakpoint) > 1 {
    $bp: nth($breakpoint, 1);
    $modifier: nth($breakpoint, 2);
  }

  @if $bp == 'small' and $modifier == 'only' {
    @media #{$type} and (max-width: $media-ranges-small-max) {
      @content;
    }
  } @else if $bp == 'medium' and $modifier == 'only' {
    @media #{$type} and (min-width: $media-ranges-medium-min) and (max-width: $media-ranges-medium-max) {
      @content;
    }
  } @else if $bp == 'medium' and $modifier == 'up' {
    @media #{$type} and (min-width: $media-ranges-medium-min) {
      @content;
    }
  } @else if $bp == 'large' and $modifier == 'only' {
    @media #{$type} and (min-width: $media-ranges-large-min) and (max-width: $media-ranges-large-max) {
      @content;
    }
  } @else if $bp == 'large' and $modifier == 'up' {
    @media #{$type} and (min-width: $media-ranges-large-min) {
      @content;
    }
  } @else if $bp == 'xlarge' and $modifier == 'only' {
    @media #{$type} and (min-width: $media-ranges-xlarge-min) {
      @content;
    }
  }
}

// This will limit height to specified number of lines, and insert ellipsis when appropriate.
@mixin multiline-text($lineHeight: 1em, $maxLines: 2) {
  height: 2.7em;
  overflow: hidden;
  position: relative;

  &::after {
    background: $white;
    bottom: 0;
    content: '\2026';
    float: right;
    margin-left: -3rem;
    position: absolute;
    right: 0;
    width: 1rem;
  }

  @supports (-webkit-line-clamp: $maxLines) {
    /* stylelint-disable */
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    display: -webkit-box;
    height: auto;
    -webkit-line-clamp: $maxLines;
    /* stylelint-enable */

    &::after {
      display: none;
    }
  }
}

@mixin grey-dashed-border() {
  background: (
    linear-gradient(to right, #EAEDF2 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(#EAEDF2 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(to right, #EAEDF2 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(#EAEDF2 50%, rgba(255, 255, 255, 0) 0%)
  );
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  background-size: 18px 2px, 2px 18px;
}

@mixin grey-dashed-border-hover() {
  background: (
    linear-gradient(to right, #D4D9E2 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(#D4D9E2 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(to right, #D4D9E2 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(#D4D9E2 50%, rgba(255, 255, 255, 0) 0%)
  );
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  background-size: 18px 2px, 2px 18px;
}
