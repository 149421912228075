@import '../../../../scss/core/vars';

$arrow-border-radius: 12px;
$size: 40px;
$default-opacity: 0.75;

.slick-slider {
  .slick-track {
    margin-left: 0;
  }
}

.carousel {
  &__left-arrow,
  &__right-arrow {
    background-color: $rasping-sandy;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    height: $size;
    justify-content: center;
    opacity: $default-opacity;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: $size;
    z-index: 1;

    &:hover {
      opacity: 1;
    }

    &.slick-disabled {
      &:hover {
        cursor: auto;
        opacity: $default-opacity;
      }
    }
  }

  &__left-arrow {
    left: 10px;
  }

  &__right-arrow {
    right: 10px;
  }
}
