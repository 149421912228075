@import '~@devsta/ui-kit/scss/core/vars';

.payment-options {
  &__body {
    background: #fff;
    border: 1px solid $cat-skill-white;
    border-radius: 1px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 15px;

    &__footer {
      color: #000;
      font-size: $font-sizes-small;
      letter-spacing: 1px;
      line-height: 17px;
      margin: auto;
      margin-bottom: 0;
    }

    &__horizontal-line {
      border: 1px solid $russian;
      margin: 18px auto;
      opacity: 0.25;
      width: 100%;
    }

    &__credit-org {
      &__header {
        font-size: $font-sizes-small;
        font-weight: bold;
        margin-bottom: 10px;
      }

      &__banner {
        align-items: center;
        background: $monkey;
        border-radius: 4px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        padding: 10px;
        width: 100%;

        &__text {
          color: $cat-skill-white;
          font-size: $font-sizes-default;
          font-weight: lighter;
          margin-left: 15px;
        }

        &.no-credit {
          background: $red-apple;  
        }
      }
    }

    .radio-button {
      &__button {
        &__center {
          background-color: $monkey;
        }
      }
    }
  }

  &__title {
    color: #000;
    font-size: $font-sizes-default;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 20px;
    margin-bottom: 10px;
    text-align: left;
  }

  @media only screen and (max-width : $media-sizes-medium) {
    &__title {
      margin-top: 15px;
    }

    &__body {
      &__footer {
        margin: 20px 0 0;
      }
    }
  }
}
