@import '~@devsta/ui-kit/scss/core/vars';

.share-settings-form {
  align-self: center;
  display: flex;
  flex-direction: column;
  max-width: 840px;
  width: 100%;

  .accordion {
    &__title-wrapper {
      margin: 10px 0;
    }
  }

  &__input-wrapper {
    margin: 10px 0;
  }

  &__buttons {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 20px;
    padding-top: 20px;

    > button:first-child {
      margin-right: 20px;
    }
  }
}
