@import '~@devsta/ui-kit/scss/core/vars';

.comments-tab-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  &__empty {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > .icon-container {
      margin-bottom: 20px;
    }
  }

  &__filter-container {
    margin-bottom: 30px;
  }

  &__heading {
    line-height: 20px;
    margin-bottom: 25px;
  }

  &__comments-list-container {
    &__comments-list {
      flex: 1;
      overflow-y: scroll;
      padding-bottom: 30px;
    }

    &__button {
      flex: 1;
      height: 50px;
      margin: 20px 0 0;
      max-height: 50px;
      width: 100%;
    }
  }

  &__popover {
    position: fixed;
    top: 50%;

    &-new {
      left: 60%;
    }

    &-existing {
      left: 70%;
    }
  }
}
