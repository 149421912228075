@import '../../../../scss/core/vars';

.action-item {
  &__separator {
    border: 0;
    border-bottom: 1px solid $russian;
    height: 0;
    margin: 10px;
  }
}
