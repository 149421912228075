@import '~@devsta/ui-kit/scss/core/vars';

@keyframes bounce-up {
  0% {   transform: scale(1, 1)       translateY(0); }
  10% {  transform: scale(1.1, 0.9)   translateY(0); }
  30% {  transform: scale(0.9, 1.1)   translateY(-5px); }
  50% {  transform: scale(1.05, 0.95) translateY(0); }
  57% {  transform: scale(1, 1)       translateY(-1px); }
  64% {  transform: scale(1, 1)       translateY(0); }
  100% { transform: scale(1, 1)       translateY(0); }
}

@keyframes bounce-down {
  0% {   transform: scale(1, 1)       translateY(0); }
  10% {  transform: scale(1.1, 0.9)   translateY(0); }
  30% {  transform: scale(0.9, 1.1)   translateY(5px); }
  50% {  transform: scale(1.05, 0.95) translateY(0); }
  57% {  transform: scale(1, 1)       translateY(1px); }
  64% {  transform: scale(1, 1)       translateY(0); }
  100% { transform: scale(1, 1)       translateY(0); }
}

.credits-overview {
  box-sizing: border-box;
  width: 100%;

  &--unlimited {
    justify-content: center;
  }

  &__inner {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: space-evenly;
    width: 100%;
  }

  &__block {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 200px;

    &__footer {
      height: 15px;
      min-height: 15px;

      &__text {
        &--negative {
          > .icon-container {
            animation-duration: 2s;
            animation-name: bounce-down;
            animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
          }
        }

        &--positive {
          > .icon-container {
            animation-duration: 2s;
            animation-name: bounce-up;
            animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
          }
        }
      }
    }
  }
}
