@import '~@devsta/ui-kit/scss/core/vars';

.srt-captions {
  margin-top: 20px;
  width: 100%;

  > .caption:not(:last-of-type) {
    margin-bottom: 20px;
  }
}
