@import '~@devsta/ui-kit/scss/core/vars';

.autosave {
  &__loading {
    height: 3px;
    position: absolute;
    top: 0;
    width: 100%;

    &__bar {
      background: $aqua;
      height: 3px;
      transition: 0.3s;
    }
  }
}
