@import '~@devsta/ui-kit/scss/core/vars';

.credit-menu {
  max-height: 305px;
  overflow-y: auto;
  width: 250px;

  &--no-teams {
    text-align: center;
  }

  &__teams {
    &__team {
      display: flex;
      padding: 10px;

      &__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 10px;

        > p {
          font-size: $font-sizes-small;
          font-weight: $font-weights-medium;
          margin-bottom: 0;
        }

        &__credits {
          align-items: center;
          display: flex;

          > .text {
            margin-left: 5px;
          }
        }
      }
    }
  }
}
