@import '~@devsta/ui-kit/scss/core/vars';

.progress {
  height: 10px;

  &__value {
    color: $nevada;
    display: block;
    font-size: $font-sizes-micro;
    font-weight: $font-weights-regular;
    letter-spacing: 1px;
    line-height: 12px;
    margin-top: 2px;
    padding-right: 25px;
    text-align: end;
    word-spacing: -2px;

    &--none {
      opacity: 0.3;
    }
  }

  .react-sweet-progress-line-inner {
    min-height: 5px;

    /* Disable active animation */
    &::before {
      display: none;
    }
  }

  .react-sweet-progress-symbol {
    margin: 7px 0 10px 8px;
    min-width: 17px;
    opacity: 1;
    padding: 0;
    transition: opacity 0.5s ease-in;
    width: 17px;
  }
}
