@import '~@devsta/ui-kit/scss/core/vars';

.comment-replies {
  border-left: 1px solid $russian;
  margin-left: 25px;
  margin-top: 10px;
  padding: 5px 0 5px 20px;

  &__toggle-more-replies {
    margin-bottom: 20px;
  }

  &__reply:not(:last-of-type) {
    border-bottom: 1px solid $russian;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}
