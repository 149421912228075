@import '../../../../scss/core/vars';

.breadcrumbs {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  margin-right: 10px;
  padding: 0;
  white-space: nowrap;

  &__breadcrumb {
    align-items: center;
    display: flex;
    font-size: 25px;
    font-weight: 200;

    &:not(:last-of-type) {
      margin-right: 8px;
    }

    &__link {
      margin-right: 8px;
      text-decoration-color: $nevada;

      &:hover,
      &:focus {
        color: $nevada;
        text-decoration: underline;
      }

      &--dark-mode {
        text-decoration-color: $white;
      }
    }
  }
}
