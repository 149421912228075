.comment-footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  &__right {
    display: flex;

    > .icon-container {
      margin: 0 12px;
    }
  }
}
