@import '~@devsta/ui-kit/scss/core/vars';

.upload-asset-description {
  margin-left: 10px;
  margin-right: 50px;
  overflow: hidden;

  > p {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:last-of-type {
      color: $regent;
      font-size: 10px;
    }
  }
}
