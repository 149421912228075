@import '../../../../scss/core/vars';

$arrow-width: 38px;

.stages {
  box-sizing: border-box;
  //overflow: hidden;
  width: 100%;

  &__dropdown {
    max-width: 250px;
  }

  &__list {
    display: flex;

    > .stage:not(:first-of-type) {
      margin-left: -10px;
    }
  }

  &__mobile {
    background: $aqua;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
    width: 100%;

    > div,
    > div:hover {
      background: transparent;
      border: 0;
    }

    &::after {
      border-color: transparent transparent transparent $aqua;
      border-style: solid;
      border-width: 25px 0 25px 30px;
      content: '';
      height: 0;
      position: absolute;
      right: -30px;
      top: 0;
      width: 0;
      z-index: 1;
    }

    &__label {
      font-size: 17px;
      text-transform: uppercase;

      &--active {
        font-weight: $font-weights-bold;
      }
    }
  }
}
