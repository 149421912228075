@import '~@devsta/ui-kit/scss/core/vars';


.submissions-graph {
  background: $white;
  height: 240px;
  margin-right: 5px;
  width: 50%;

  @media screen and (max-width: $media-ranges-medium-max) {
    margin: 0 0 20px;
    width: 100%;
  }

  &__wrapper {
    height: 240px;
    overflow-x: scroll;
    width: 100%;

    &__container {
      height: 100%;
      width: 1300px;
    }
  }
}
