@import '~@devsta/ui-kit/scss/core/vars';

.comment-controls {
  &__container {
    display: flex;
    font-weight: $font-weights-medium;
    justify-content: space-between;
    margin-top: 15px;
  
    &__controls {
      display: flex;

      div:first-of-type {
        margin-right: 10px;
      }
    }

    &__edit-comment-controls {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &__save {
        border-color: transparent;
      }

      &__cancel {
        color: $regent;
        font-size: $font-sizes-micro;
        font-weight: $font-weights-medium;
      }
    }
  }
}
