@import '~@devsta/ui-kit/scss/core/vars';

.total-summary {
  margin-bottom: 10px;

  &__content {
    display: flex;
    justify-content: space-between;

    &__text {
      color: #000;
      font-size: 8px;
      letter-spacing: 1px;
      line-height: 17px;
    }

    &__cost {
      font-weight: bold;
    }
  }
}
