@import '~@devsta/ui-kit/scss/core/vars';

.library-drop-zone {
  align-items: center;
  border: 3px dashed $russian;
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  opacity: 0.5;
  padding: 165px;

  &--small {
    margin-top: 40px;
    padding: 100px 30px;
  }

  &__container {
    display: flex;
    flex-grow: 1;

    > div:first-child {
      flex-grow: 1;
    }
  }

  &__primary-text {
    color: $nevada;
    font-size: $font-sizes-large;
    letter-spacing: 1px;
    line-height: 25px;
    text-align: center;
  }

  &__secondary-text {
    color: $regent;
    font-size: $font-sizes-small;
    letter-spacing: 1px;
    line-height: 15px;
    text-align: center;
  }

  &:hover {
    opacity: 1;
  }

  &--hidden {
    display: none;
  }

  > p {
    margin: 0;
  }

  &--active {
    background: $monkey;
    border-color: transparent;
    opacity: 1;

    .library-drop-zone {
      &__primary-text,
      &__secondary-text,
      &__text-underlined {
        color: $white;
      }

    }
  }
}


