@import '~@devsta/ui-kit/scss/core/vars';

.post-video-details {
  align-items: center;
  display: flex;
  width: 100%;

  > div {
    overflow: hidden;
    white-space: nowrap;
  }

  > .image,
  > .asset-preview {
    margin-right: 20px;
  }

  &__subtitle {
    color: $regent;
    font-size: $font-sizes-small;
    margin: 0 0 0 15px;
  }

  > .asset-preview {
    min-width: 75px;
  }

  &__name,
  &__source {
    font-size: 15px;
    margin: 0 0 0 15px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__source {
    color: $nevada;
    font-size: $font-sizes-small;

    > span {
      text-decoration: underline;
    }
  }

  &__date {
    color: $nevada;
    font-size: $font-sizes-small;
    margin: 0 0 0 15px;
  }
}
