@import '../../../../scss/core/vars';
@import '../../../common/styles/mixins/button';
$multi-arrow-container-width: 78px;

/* stylelint-disable */

.react-select__value-container--is-multi.react-select__value-container--has-value {
  /* stylelint-enable */
  padding-bottom: 5px;
  padding-top: 6px;
}

.react-select {
  &__value-container {
    &--is-multi {
      max-width: calc(100% - #{$multi-arrow-container-width});
    }
    .dropdown-option__content__label {
      font-weight: $font-weights-medium;
    }
  }
  &__indicators {
    .dropdown-option__suffix {
      margin-right: 10px;
    }
  }
  &__input > input {
    font-size: 14px !important;
    font-weight: $font-weights-regular;
    line-height: 22px;
  }
  &__control {
    &--menu-is-open {
      background-color: $sterling !important;
    }
  }
  &__value-container {
    padding-left: 20px !important;
    padding-right: 20px !important;
    min-height: 20px !important;
  }
  &__menu-notice {
    &--no-options,
    &--loading {
      font-size: 14px !important;
      font-weight: $font-weights-regular;
      line-height: 22px;
    }
  }
}

.dropdown {
  &--btn {
    .react-select {
      &__control {
        @include button--tertiary;
      }
      &--is-disabled &__control {
        @include button--disabled;
      }
    }
  }
  &--btn.react-select--is-disabled {
    .react-select {
      &__control {
        @include button--disabled;
        .dropdown-option {
          &__content {
            &__label {
              color: $nevada;
            }
          }
        }
      }
    }
  }
}