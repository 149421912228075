@import '~@devsta/ui-kit/scss/core/vars';

.event-item {
  display: flex;

  &__side {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
    max-width: 20px;
  }

  &:not(:last-of-type) &__side {
    &__line {
      background-image: linear-gradient($russian, $russian);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 1px 100%;
      height: 100%;
      margin: 12px 0;
    }
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-bottom: 15px;

    &__action {
      font-size: $font-sizes-small;
      margin: 0;
    }

    &__date {
      color: $nevada;
      font-size: $font-sizes-small;
      font-weight: 400;
      margin: 0 0 20px;
    }
  }
}
