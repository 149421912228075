@import '~@devsta/ui-kit/scss/core/vars';

.addons-payment {
  &__title {
    font-weight: bold;
    margin-bottom: 30px;

    &__primary {
      align-self: center;
      color: #000;
      display: flex;
      flex: 1 1 100%;
      font-size: 24px;
      justify-content: center;
      letter-spacing: 2px;
      line-height: 30px;
      margin: 0;
      margin-bottom: 10px;
      text-align: center;
    }

    &__secondary {
      align-self: center;
      color: #000;
      display: flex;
      flex: 1 1 100%;
      font-size: $font-sizes-default;
      justify-content: center;
      letter-spacing: 1px;
      line-height: 20px;
      margin: 0;
      text-align: center;
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;

    &__addons {
      flex: 1 1 35%;
    }

    &__payments {
      display: flex;
      flex-direction: column;
    }
  }

  @media only screen and (max-width : $media-sizes-medium) {
    &__body {
      flex-direction: column;
    }
  }
}
