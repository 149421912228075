@import '~@devsta/ui-kit/scss/core/vars';

.social-icon {
  background: $big-fat;
  border-radius: 50%;
  padding: 6px;

  &--highlighted {
    &.social-icon-website {
      background: #086cb4;
    }

    &.social-icon-facebook {
      background: #4267b2;
    }

    &.social-icon-twitter {
      background: #00a2f8;
    }

    &.social-icon-linkedin {
      background: #0077b5;
    }

    &.social-icon-other {
      background: $regent;
    }
  }
}
