@import '~@devsta/ui-kit/scss/core/vars';
.editable-comment {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 25px 25px 32px;
  width: 375px;
  &__header-content-wrapper {
    z-index: 2;
  }
}