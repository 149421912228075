.comment-indicator {
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: 10px;
  padding: 0;
  position: absolute;
  top: 0;
  width: 10px;
}
