.font-preview {
  align-content: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;

  &__snippet {
    align-items: center;
    display: flex;
    font-size: 48px;
    line-height: 48px;
    margin: 0;
    text-align: center;
    user-select: none;

    &--small {
      font-size: 15px;
      line-height: 15px;
    }
  }
}
