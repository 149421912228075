@import '~@devsta/ui-kit/scss/core/vars';

.library-list-item {
  align-items: center;
  display: flex;
  width: 100%;

  .user-details {
    &__container {
      width: auto;
    }
  }

  &--small {
    flex-direction: column;

    .library-list-item {
      &__column {
        padding-right: 10px;
        width: 100%;

        &:first-of-type {
          margin-bottom: 20px;
        }
      }

      &__date {
        margin-right: 0;
        width: 100%;
      }
    }
  }

  &__column {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
    width: 49%;

    &:first-of-type {
      justify-content: flex-start;
    }
  }

  &__date {
    align-items: center;
    margin-right: 10px;
  }
}
