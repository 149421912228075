.comments-overlay {
  cursor: crosshair;
  height: 100%;
  position: absolute;
  width: 100%;

  &__tooltip {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
    cursor: crosshair;
    padding: 5px 15px;
    transform: translateY(-50%);
  }

  &__interactive {
    background: none;
    border: 0;
    color: inherit;
    cursor: crosshair;
    font: inherit;
    height: 100%;
    outline: inherit;
    padding: 0;
    width: 100%;
  }
}
