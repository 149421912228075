@import '~@devsta/ui-kit/scss/core/vars';

.video-placeholder {
  align-items: center;
  box-sizing: border-box;
  color: $white-75;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 30px 30px 75px;
  text-align: center;
  user-select: none;
  width: 100%;

  &__icon {
    height: auto;
    margin-bottom: 5%;
    max-width: 140px;
    width: 15%;

    > svg {
      height: auto;
      max-height: 100%;
      width: 100%;
    }
  }

  &--dark {
    background-color: $black;
    color: $monkey;
  }

  &__text {
    > .text {
      justify-content: center;
    }
  }
}
