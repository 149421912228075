.nav-menu-mobile {
  &__menu {
    > button {
      margin-left: 5px;
    }

    &__org {
      align-items: center;
      display: flex;
      padding-left: 20px;

      > .text {
        margin-left: 20px;
      }
    }

    &__links {
      > button:not(:last-of-type) {
        margin-bottom: 5px;
      }
    }
  }
}
