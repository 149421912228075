@import '~@devsta/ui-kit/scss/core/vars';

.reply {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  padding-bottom: 5px;

  &__user-profile {
    align-items: center;
    display: flex;
    margin-bottom: 5px;

    > span {
      font-size: $font-sizes-small;
      font-weight: $font-weights-medium;
      margin-left: 10px;
    }
  }

  > .content-view {
    font-size: $font-sizes-micro;
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

    &__reply {
      font-size: $font-sizes-micro;
      font-weight: $font-weights-bold;
    }

    &__row {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    &__trash-icon {
      margin: 0 12px;
    }
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid $cat-skill-white;
  }
}
