@import '~@devsta/ui-kit/scss/core/vars';

.header {
  display: flex;
  justify-content: space-between;

  &__profile-image-wrapper {
    align-items: center;
    display: flex;

    &__text {
      display: flex;
      flex-direction: column;
      margin-left: 15px;
    }
  }

  .check-box {
    height: 20px;
    width: 20px;
  }
}
