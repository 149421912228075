@import '../../../../scss/core/vars';

.image {
  &__icon {
    flex-direction: column;

    &__subtitle {
      margin-top: 5px;
    }
  }
}
