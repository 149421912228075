@import '~@devsta/ui-kit/scss/core/vars';

.credits-wrapper {
  display: flex;
  justify-content: center;
  position: relative;

  &__credits-info {
    align-items: center;
    background: $white;
    border: 1px solid $black !important;
    box-sizing: border-box;
    color: $black;
    display: flex;
    font-size: 12px;
    font-weight: $font-weights-medium;
    height: 30px !important;
    justify-content: center;
    position: relative;
    width: 60px !important;
    
    &__credits {
      align-items: center;
      display: flex;
  
      > .icon-container {
        margin-right: 5px;
      }
    }
  }
}
