@import '../../../../scss/core/vars';
@import '../../../common/styles/mixins/button';

.button {
  @include button;

  &__content {
    align-items: center;
    display: flex;
    height: 100%;

    >*:last-child:not(:first-child):not(.text) {
      margin-left: 10px;
    }

    > *:first-child:not(:last-child):not(.text) {
      margin-right: 10px;
    }
  }

  &--primary {
    background: $monkey;

    .text--button {
      color: $white;
    }

    &:hover {
      background: $aqua;
    }

    &:focus {
      background: $bermuda-blue;
    }
  }

  &--primary.button--busy {
    background: $bermuda-blue;
  }

  &--secondary {
    background: $denim;

    .text--button {
      color: $white;
    }

    &:hover {
      background: $light-denim;
    }

    &:focus {
      background: $dark-denim;
    }
  }

  &--secondary.button--busy {
    background: $dark-denim;
  }

  &--tertiary {
    @include button--tertiary;
  }

  &--tertiary.button--busy {
    background: $sterling;
    border-color: $sterling;
  }

  &--disabled {
    @include button--disabled;
  }

  &--busy {
    pointer-events: none;

    &__icon {
      position: absolute;
    }
  }

  &--busy > &__content {
    opacity: 0;
  }

  &--icon-only {
    height: 40px;
    min-height: 40px;
    min-width: 40px;
    padding: 0;
    width: 40px;
  }
}
