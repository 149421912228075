@import '~@devsta/ui-kit/scss/core/vars';

.delete-confirmation-warning {
  align-items: center;
  display: flex;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }

  > .text {
    margin-left: 10px;
  }
}
