@import '~@devsta/ui-kit/scss/core/vars';

.payments-invoice-modal {
  .rodal-dialog {
    transition: width 0.3s, max-width 0.3s, min-width 0.3s;

    .modal {
      &__content {
        transition: width 0.3s, max-width 0.3s, min-width 0.3s;
      }
    }
  }

  @media only screen and (max-width : $media-sizes-medium) {
    .modal {
      &__content {
        min-width: initial;
      }
    }
  }
}

.output-changes-modal {
  .rodal-dialog {
    .modal {
      max-width: 750px;
      padding: 45px 50px 35px;
    }
  }
}
