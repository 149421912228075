@import '~@devsta/ui-kit/scss/core/vars';


.basic-user-profile {
  align-items: center;
  display: flex;
  flex-direction: column;

  &__details {
    max-width: 700px;
  }

  &__split-row {
    display: flex;
    flex-direction: row;
    //flex-wrap: wrap;
    width: 100%;

    > * {
      &:first-child {
        margin-right: 25px;
        z-index: 5;
      }
    }
  }

  > button {
    margin-bottom: 40px;
  }

  &__fields-container {
    box-sizing: border-box;
    margin-bottom: 50px;
    margin-top: 35px;
    max-width: 700px;
    width: 100%;

    > label {
      display: block;
    }

    > *:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  &__split-column {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
  }

  @media only screen and (max-width: $media-sizes-small) {
    &__split-row {
      flex-direction: column;

      > * {
        margin-bottom: 0;

        &:not(:last-child) {
          margin-bottom: 25px;
        }
      }
    }

    &__split-column {
      flex: 1;
    }
  }
}
