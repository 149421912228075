@import '~@devsta/ui-kit/scss/core/vars';

.navigation-footer {
  align-items: center;
  background: $white;
  border-top: 1px solid $russian;
  box-sizing: border-box;
  display: flex;
  height: 75px;
  justify-content: space-between;
  min-height: 75px;
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;

  &__children {
    flex: 1;

    // Will still expand with flex 1 and have width, but won't break width layouts
    width: 0;
  }

  > * {
    align-items: center;
    display: flex;

    > *:first-child:not(:last-child) {
      margin-right: 20px;
    }
  }
}
