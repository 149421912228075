@import '~@devsta/ui-kit/scss/core/vars';

.library-asset-details {
  flex-basis: 440px;
  margin-left: 30px;

  &__text {
    font-size: $font-sizes-small;
    margin: 0;
  }

  &__right-content {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__title {
    font-size: $font-sizes-default;
    margin-bottom: 0;
    white-space: normal;
  }

  &__sub-title {
    color: $nevada;
    font-size: $font-sizes-small;
  }

  &__filename {
    font-size: $font-sizes-small;
    margin-bottom: 1px;
    margin-top: 15px;
    white-space: normal;
  }

}
