@import '~@devsta/ui-kit/scss/core/vars';

.video-preview {
  align-items: center;
  background: $black;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;

  &__transcoding-icon {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .image {
    margin-left: -2px;
  }

  &__video {
    border-radius: 6px;
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
}
