@import '../../../scss/core/vars';

.bulk-actions {
  align-items: center;
  display: inline-flex;
  height: 25px;

  &__select-label {
    background: none;
    border: 0;
    cursor: pointer;
    margin-left: 10px;
    padding: 0;
    text-align: left;
  }

  &--partial {
    .check-box {
      &__box {
        border-color: $regent !important;

        &::after {
          box-sizing: border-box;
          color: $regent;
          content: '-';
          font-size: 19px;
          padding-left: 2px;
          padding-top: 2px;
        }

        &__check-icon {
          display: none !important;
        }
      }
    }
  }

  &__selected {
    align-items: center;
    display: flex;
    font-size: $font-sizes-small;
    margin-bottom: 0;
    margin-left: 10px;
    margin-top: 0;
    min-width: 115px;

    &__number.text {
      &--body {
        align-items: center;
        background: $monkey;
        border-radius: 4px;
        box-sizing: border-box;
        color: $white;
        display: flex;
        height: 20px;
        justify-content: center;
        margin-right: 10px;
        min-width: 30px;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  &__actions {
    align-items: center;
    display: flex;
    margin-left: 40px;
    padding-left: 40px;
    position: relative;

    &::before {
      background: $russian;
      content: '';
      height: 30px;
      left: 0;
      position: absolute;
      width: 1px;
    }

    > *:not(:last-child) {
      margin-right: 20px;
    }
  }

  &--dark-mode {
    background: $mine-shaft;
    border-radius: 6px;
    height: 50px;
    padding-left: 15px;
    padding-right: 15px;
  }

  &--dark-mode &__select-label {
    color: $white;
  }

  &--dark-mode &__selected > span {
    color: $white;
  }

  &--dark-mode &--partial {
    .check-box {
      &__box {
        &::after {
          color: $white !important;
        }
      }
    }
  }
}
