@import '~@devsta/ui-kit/scss/core/vars';

.history-tab {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  &__list {
    flex: 1;
    overflow-y: auto;

    &__day-activities {
      &:not(:last-of-type) {
        border-bottom: 1px solid $russian;
        margin-bottom: 40px;
      }

      &__date {
        margin-bottom: 15px;

        &--today {
          color: $monkey !important;
        }
      }
    }
  }
}
