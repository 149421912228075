@import '~@devsta/ui-kit/scss/core/vars';

.credits-status-bar {
  border-radius: 8px;
  margin-bottom: 10px;
  max-width: 180px;
  overflow: hidden;
  width: 100%;

  &__bar {
    background: $big-fat;
    display: block;
  }

  &__progress {
    background: $aqua;
    display: block;
    height: 5px;
    transition: width 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  }
}
