@import '~@devsta/ui-kit/scss/core/vars';

.share-modal-advanced {
  &__container {
    &__header {
      display: inline-block;
    }

    &__content {
      background: $white;
      border: 1px solid $cat-skill-white;
      border-radius: 6px;
      margin-bottom: 20px;
      padding: 25px 35px;

      .radio-button {
        &__button {
          &__center {
            background: $monkey;
            height: 14px;
            width: 14px;
          }
        }
      }

      > .radio-button:not(:last-of-type) {
        margin-bottom: 15px;
      }

      > hr {
        background-color: $russian;
        border: 0;
        height: 1px;
        margin-bottom: 25px;
        margin-top: 25px;
      }

      &__setting {
        align-items: center;
        display: flex;
        font-size: $font-sizes-small;
        justify-content: space-between;
        letter-spacing: 1px;
        line-height: 17px;
        margin-top: 10px;

        > button {
          min-width: 155px;
        }

        &__dropdown {
          max-width: 185px;
        }

        > *:first-child {
          margin-right: 10px;
        }

        > p {
          font-size: 12px;
        }
      }

      &__share-setting {
        display: flex;

        &__text {
          > p {
            font-size: $font-sizes-small;
            letter-spacing: 1px;
            line-height: 17px;
            margin: 0;
          }
        }

        > .icon-container {
          margin-right: 15px;
        }
      }
    }

    > .icon-container {
      display: inline;
      margin-left: 5px;
      vertical-align: text-top;
    }
  }
}


