@import '~@devsta/ui-kit/scss/core/vars';

.share-modal-access-permission {
  &__container {
    box-sizing: border-box;

    &__header {
      align-items: flex-start;
      display: flex;

      > .text {
        margin-right: 5px;
      }
    }

    > button {
      min-width: 155px;
    }

    > .icon-container {
      display: inline;
      margin-left: 5px;
      vertical-align: text-top;
    }

    &__content {
      align-items: center;
      background: $white;
      border: 1px solid $cat-skill-white;
      border-radius: 6px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      overflow-y: auto;
      padding: 30px 20px;
      width: 100%;

      &__text {
        font-size: $font-sizes-small;
        letter-spacing: 1px;
        line-height: 17px;
        margin: 0;
      }
    }

    &__drop-down {
      min-width: 240px;
      width: 240px;
    }
  }
}
